export default {
  "c-footer-menu-trade": "p2p 交易",
  "c-footer-menu-news": "新闻",
  "c-footer-menu-blog": "博客",
  "c-footer-menu-faq": "常见问题",
  "c-footer-menu-beginnersGuide": "新手指南",
  "c-footer-menu-about": "关于",
  "c-footer-menu-manageCookies": "管理 Cookie",
  "c-footer-menu-adminPanel": "管理面板",

  "c-footer-mobilemenu-home": "主页",
  "c-footer-mobilemenu-trade": "交易",
  "c-footer-mobilemenu-orders": "订单",
  "c-footer-mobilemenu-wallet": "钱包",

  "c-footer-menu-serviceDescription": "一个快速交换加密货币和法币的P2P平台服务",
  "c-footer-menu-systemsNormal": "所有系统正常运行",
  "c-footer-menu-privacyPolicy": "隐私政策",
  "c-footer-menu-instagramDescription":
    "关注我们的Instagram，获取视觉内容和加密行业新闻的灵感！",
  "c-footer-menu-linkedinDescription":
    "加入我们的LinkedIn页面，建立专业联系并深入了解加密货币的商业方面。",
  "c-footer-menu-xDescription":
    "关注我们在X.com的更新，随时掌握趋势和加密市场分析！",
  "c-footer-menu-telegramDescription":
    "加入我们的Telegram，第一时间了解重要新闻并分享您对加密货币的看法！",
  "c-footer-menu-vkDescription": "加入我们的VK，讨论最新的加密货币新闻",
};
