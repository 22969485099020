export default {
  "c-footer-menu-trade": "P2P-Handel",
  "c-footer-menu-news": "Nachrichten",
  "c-footer-menu-blog": "Blog",
  "c-footer-menu-faq": "FAQ",
  "c-footer-menu-beginnersGuide": "Anfängerleitfaden",
  "c-footer-menu-about": "Über",
  "c-footer-menu-manageCookies": "Cookies verwalten",
  "c-footer-menu-adminPanel": "Admin-Panel",

  "c-footer-mobilemenu-home": "Startseite",
  "c-footer-mobilemenu-trade": "Handel",
  "c-footer-mobilemenu-orders": "Aufträge",
  "c-footer-mobilemenu-wallet": "Brieftasche",

  "c-footer-menu-serviceDescription":
    "Ein Service für den schnellen Austausch von Kryptowährungen und Fiat auf einer P2P-Plattform",
  "c-footer-menu-systemsNormal": "Alle Systeme sind betriebsbereit",
  "c-footer-menu-privacyPolicy": "Datenschutzrichtlinie",
  "c-footer-menu-instagramDescription":
    "Folgen Sie unserem Instagram, um sich von visuellen Inhalten und Neuigkeiten aus der Kryptoindustrie inspirieren zu lassen!",
  "c-footer-menu-linkedinDescription":
    "Treten Sie unserer LinkedIn-Seite bei, um professionelle Verbindungen zu knüpfen und die geschäftlichen Aspekte von Kryptowährungen zu vertiefen.",
  "c-footer-menu-xDescription":
    "Folgen Sie unseren Updates auf X.com, um über Trends und Analysen des Kryptomarktes informiert zu bleiben!",
  "c-footer-menu-telegramDescription":
    "Treten Sie unserem Telegram bei, um wichtige Nachrichten als Erster zu erfahren und Ihre Gedanken zur Kryptowährung zu teilen!",
  "c-footer-menu-vkDescription":
    "Treten Sie unserem VK bei, um die neuesten Nachrichten über Kryptowährungen zu diskutieren",
};
