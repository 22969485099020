export default {
  "p-index-head-title":
    "Solid Swap | Sicherer und effizienter Kryptowährungsaustausch",
  "p-index-head-description":
    "Erleben Sie schnellen, sicheren und effizienten Kryptowährungshandel mit Solid Swap. Treten Sie jetzt bei, um mit dem Tausch von digitalen Vermögenswerten zu beginnen",
  "p-index-head-keywords":
    "kryptowährungsaustausch, sicherer handel, digitale vermögenswerte, krypto-handel, Solid Swap, solidswap",
  "p-index-prices-finder": "Preisfinder:",
  "p-index-welcome-message-2":
    "Handeln Sie auf Ihre Weise, jederzeit und überall",
  "p-index-welcome-no-fees":
    "Keine Gebühren für Käufer, ultraniedrige 0,2% Verkäufergebühr",
  "p-index-welcome-stable": "Stabil, legalisiert und ALM-frei",
  "p-index-welcome-no-id": "Keine ID-Verifizierung erforderlich",
  "p-index-get-started": "Starten",
  "p-index-learn-more": "MEHR ERFAHREN",
  "p-index-world-opening-title": "Wir öffnen Ihnen die Welt",
  "p-index-world-opening-message-1":
    "Kryptowährung wird Sie frei machen, es ist die Freiheit von den Fesseln der Banken, dem Diktat der Regierung",
  "p-index-world-opening-message-2":
    "Dies ist die Freiheit der Bewegung rund um die Welt und die Fähigkeit, Ihr Geld in jedem Land zu sparen",
  "p-index-mobile-app-title": "Laden Sie die mobile App kostenlos herunter",
  "p-index-mobile-app-message":
    "Die mobile Anwendung steht zum kostenlosen Download im App Store und bei Google Play zur Verfügung",
  "p-index-coming-soon": "Kommt diesen Sommer",
  "p-index-subscribe-title": "Abonnieren Sie Solid Swap",
  "p-index-welcome-title": "Vorteile unseres P2P-Handels",
  "p-index-our-blog": "Unser Blog:",
};
