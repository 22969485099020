export default {
  "t-email-logoAltText": "Logo de SolidSwap",
  "t-email-greeting": "Hola,",
  "t-email-failedLoginAttemptMessage":
    "¡Has tenido un intento de inicio de sesión fallido en tu cuenta de SolidSwap! Tu acceso ha sido bloqueado durante 1 hora.",
  "t-email-lastFailedLoginAttemptMessage":
    "El último intento de inicio de sesión fallido fue desde este dispositivo y ubicación:",
  "t-email-serverLabel": "Servidor:",
  "t-email-ipAddressLabel": "Dirección IP:",
  "t-email-countryDistrictLabel": "País/Distrito:",
  "t-email-timestampLabel": "Marca de tiempo:",
  "t-email-compromisedAccountMessage":
    "Si crees que tu cuenta ha sido comprometida, por favor contáctanos inmediatamente por correo electrónico",
  "t-email-registeredEmailMessage":
    "utilizando tu dirección de correo electrónico registrada, o puedes hacer clic en el botón a continuación para bloquear los retiros.",
  "t-email-blockWithdrawalsButtonText": "Bloquear Retiros",
  "t-email-blockWithdrawalsButtonLabel": "Bloquear Retiros",
  "t-email-thankYouMessage": "Gracias,",
  "t-email-teamMessage": "El equipo de SolidSwap",
  "t-email-loginFromNewDeviceMessage":
    "Has iniciado sesión en tu cuenta de SolidSwap desde un nuevo dispositivo o ubicación:",
  "t-email-newExchangeRequestSubject":
    "SolidSwap: Nueva solicitud de intercambio",
  "t-email-fromCurrencyLabel": "De moneda:",
  "t-email-fromAmountLabel": "Cantidad:",
  "t-email-toCurrencyLabel": "A moneda:",
  "t-email-toAmountLabel": "Cantidad:",
  "t-email-statusLabel": "Estado:",
  "t-email-dateTimeLabel": "Fecha/Hora:",
  "t-email-requestIdLabel": "ID de solicitud:",
  "t-email-goToRequestPageButtonLabel":
    "Ir a la página de solicitud de intercambio",
  "t-email-noReplyMessage": "Por favor, no respondas a este correo.",
  "t-email-supportRequestNotification":
    "Notificación de nueva solicitud de soporte de SolidSwap",
  "t-email-userNameLabel": "Nombre de usuario:",
  "t-email-userEmailLabel": "Correo electrónico del usuario:",
  "t-email-helpMessageLabel": "Mensaje de ayuda:",
  "t-email-checkSupportChatsMessage":
    "Por favor revisa la pestaña de chats de soporte:",
  "t-email-orderCreationNotification":
    "Notificación de creación de orden de SolidSwap",
  "t-email-currencyLabel": "Moneda:",
  "t-email-quantityLabel": "Cantidad:",
  "t-email-goToOrderPageButtonLabel": "Ir a la página de la orden",
  "t-email-passwordResetRequestMessage":
    "¡Has solicitado restablecer tu contraseña!",
  "t-email-passwordResetInstructionMessage":
    "Por favor, procede a restablecer la contraseña de tu cuenta de SolidSwap haciendo clic en el botón a continuación:",
  "t-email-resetPasswordButtonLabel": "Restablecer Contraseña",
  "t-email-linkValidityMessage": "El enlace será válido durante 15 minutos.",
  "t-email-errorNotificationMessage":
    "Si crees que este correo ha sido enviado por error, por favor notifica inmediatamente al equipo de registro de SolidSwap",
  "t-email-alternateLinkInstruction":
    "Si el enlace de activación anterior no funciona, por favor copia el enlace a continuación y pégalo en la barra de direcciones de tu navegador web y presiona Enter:",
  "t-email-confirmAccountMessage":
    "Por favor, confirma tu cuenta de SolidSwap haciendo clic en el botón a continuación:",
  "t-email-confirmAccountButtonLabel": "Confirmar Tu Cuenta",
  "t-email-memberNameMessage": "Tu nombre de miembro de SolidSwap es:",
  "t-email-accessSiteMessage": "Puedes acceder al sitio visitando",
  "t-email-activate2FAInstructions":
    "Para comenzar a operar o hacer tu primer depósito, necesitarás activar la autenticación de dos factores. Puedes hacerlo en la configuración de tu perfil o hacer clic",
  "t-email-hereLabel": "aquí",
  "t-email-beginnersGuideMessage":
    "Te recomendamos comenzar revisando nuestra Guía para Principiantes",
  "t-email-communityWelcomeMessage":
    "Esperamos verte en la comunidad de SolidSwap.",
  "t-email-exchangeStatusChangeNotification":
    "SolidSwap Solicitud de intercambio: Notificación de cambio de estado",
  "t-email-withdrawalNotification": "Notificación de retiro de SolidSwap",
  "t-email-withdrawToLabel": "Retirar a:",
  "t-email-ipLabel": "IP:",
  "t-email-locationLabel": "Ubicación:",
  "t-email-unauthorizedActivityMessage":
    "Si no reconoces esta actividad, por favor haz clic en el botón a continuación para cancelar la transacción y deshabilitar los retiros en tu cuenta. El enlace es válido solo por 10 minutos.",
  "t-email-cancelTransactionButtonLabel": "Cancelar Transacción",
  "t-email-withdrawCode": "Su código de retiro es:",
};
