export default {
  "c-footer-menu-trade": "p2p փոխանակում",
  "c-footer-menu-news": "նորություններ",
  "c-footer-menu-blog": "բլոգ",
  "c-footer-menu-faq": "ՀՏՀ",
  "c-footer-menu-beginnersGuide": "սկսնակների ուղեցույց",
  "c-footer-menu-about": "մեր մասին",
  "c-footer-menu-manageCookies": "կառավարել թխվածքները",
  "c-footer-menu-adminPanel": "Ադմին վահանակ",

  "c-footer-mobilemenu-home": "Գլխավոր էջ",
  "c-footer-mobilemenu-trade": "Փոխանակում",
  "c-footer-mobilemenu-orders": "Պատվերներ",
  "c-footer-mobilemenu-wallet": "Դրամապանակ",

  "c-footer-menu-serviceDescription":
    "Ծառայություն՝ կրիպտոարժույթների և ֆիատի արագ փոխանակման համար P2P հարթակում",
  "c-footer-menu-systemsNormal": "Բոլոր համակարգերը նորմալ են աշխատում",
  "c-footer-menu-privacyPolicy": "Գաղտնիության քաղաքականություն",
  "c-footer-menu-instagramDescription":
    "Բաժանորդագրվեք մեր Instagram-ին՝ տեսողական բովանդակությամբ և կրիպտոարդյունաբերության նորություններով ներշնչվելու համար:",
  "c-footer-menu-linkedinDescription":
    "Միացեք մեր LinkedIn էջին՝ մասնագիտական կապեր ստեղծելու և կրիպտոարժույթների բիզնես ասպեկտները խորացնելու համար:",
  "c-footer-menu-xDescription":
    "Հետևեք մեր թարմացումներին X.com-ում՝ կրիպտոշուկայի տենդենցների և վերլուծության մասին տեղեկացված լինելու համար:",
  "c-footer-menu-telegramDescription":
    "Միացեք մեզ Telegram-ում՝ առաջինը իմանալու կարևոր նորություններն ու կիսվելու ձեր մտքերով կրիպտոարժույթների մասին:",
  "c-footer-menu-vkDescription":
    "Միացեք մեզ VK-ում՝ կրիպտոարժույթների վերջին նորությունները քննարկելու համար",
};
