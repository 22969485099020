export default {
  "c-footer-menu-trade": "commerce p2p",
  "c-footer-menu-news": "nouvelles",
  "c-footer-menu-blog": "blog",
  "c-footer-menu-faq": "FAQ",
  "c-footer-menu-beginnersGuide": "guide du débutant",
  "c-footer-menu-about": "à propos",
  "c-footer-menu-manageCookies": "gérer les cookies",
  "c-footer-menu-adminPanel": "Panneau d'administration",

  "c-footer-mobilemenu-home": "Accueil",
  "c-footer-mobilemenu-trade": "Commerce",
  "c-footer-mobilemenu-orders": "Commandes",
  "c-footer-mobilemenu-wallet": "Portefeuille",

  "c-footer-menu-serviceDescription":
    "Un service pour échanger rapidement des cryptomonnaies et des devises sur une plateforme P2P",
  "c-footer-menu-systemsNormal": "Tous les systèmes fonctionnent normalement",
  "c-footer-menu-privacyPolicy": "Politique de Confidentialité",
  "c-footer-menu-instagramDescription":
    "Suivez notre Instagram pour vous inspirer avec du contenu visuel et des nouvelles de l'industrie des cryptomonnaies !",
  "c-footer-menu-linkedinDescription":
    "Rejoignez notre page LinkedIn pour établir des connexions professionnelles et explorer les aspects commerciaux des cryptomonnaies.",
  "c-footer-menu-xDescription":
    "Suivez nos mises à jour sur X.com pour rester informé des tendances et des analyses du marché des cryptomonnaies !",
  "c-footer-menu-telegramDescription":
    "Rejoignez-nous sur Telegram pour être les premiers informés des nouvelles importantes et partager vos idées sur les cryptomonnaies !",
  "c-footer-menu-vkDescription":
    "Rejoignez-nous sur VK pour discuter des dernières nouvelles des cryptomonnaies",
};
