export default {
  "t-email-logoAltText": "Logo SolidSwap",
  "t-email-greeting": "Bonjour,",
  "t-email-failedLoginAttemptMessage":
    "Vous avez effectué une tentative de connexion échouée sur votre compte SolidSwap ! Votre accès a été bloqué pendant 1 heure.",
  "t-email-lastFailedLoginAttemptMessage":
    "La dernière tentative de connexion échouée provenait de cet appareil et de cet emplacement :",
  "t-email-serverLabel": "Serveur :",
  "t-email-ipAddressLabel": "Adresse IP :",
  "t-email-countryDistrictLabel": "Pays/District :",
  "t-email-timestampLabel": "Horodatage :",
  "t-email-compromisedAccountMessage":
    "Si vous pensez que votre compte a été compromis, veuillez nous contacter immédiatement par e-mail",
  "t-email-registeredEmailMessage":
    "en utilisant votre adresse e-mail enregistrée, ou vous pouvez cliquer sur le bouton ci-dessous pour bloquer les retraits.",
  "t-email-blockWithdrawalsButtonText": "Bloquer les retraits",
  "t-email-blockWithdrawalsButtonLabel": "Bloquer les retraits",
  "t-email-thankYouMessage": "Merci,",
  "t-email-teamMessage": "L'équipe SolidSwap",
  "t-email-loginFromNewDeviceMessage":
    "Vous vous êtes connecté à votre compte SolidSwap à partir d'un nouvel appareil ou emplacement :",
  "t-email-newExchangeRequestSubject": "SolidSwap : Nouvelle demande d'échange",
  "t-email-fromCurrencyLabel": "De la devise :",
  "t-email-fromAmountLabel": "Montant :",
  "t-email-toCurrencyLabel": "À la devise :",
  "t-email-toAmountLabel": "Montant :",
  "t-email-statusLabel": "Statut :",
  "t-email-dateTimeLabel": "Date/Heure :",
  "t-email-requestIdLabel": "ID de demande :",
  "t-email-goToRequestPageButtonLabel": "Aller à la page de demande d'échange",
  "t-email-noReplyMessage": "Veuillez ne pas répondre à cet e-mail.",
  "t-email-supportRequestNotification":
    "SolidSwap Nouvelle notification de demande d'assistance",
  "t-email-userNameLabel": "Nom d'utilisateur :",
  "t-email-userEmailLabel": "E-mail de l'utilisateur :",
  "t-email-helpMessageLabel": "Message d'aide :",
  "t-email-checkSupportChatsMessage":
    "Veuillez vérifier l'onglet des chats de support :",
  "t-email-orderCreationNotification":
    "Notification de création de commande SolidSwap",
  "t-email-currencyLabel": "Devise :",
  "t-email-quantityLabel": "Quantité :",
  "t-email-goToOrderPageButtonLabel": "Aller à la page de la commande",
  "t-email-passwordResetRequestMessage":
    "Vous avez demandé une réinitialisation du mot de passe !",
  "t-email-passwordResetInstructionMessage":
    "Veuillez procéder à la réinitialisation du mot de passe de votre compte SolidSwap en cliquant sur le bouton ci-dessous :",
  "t-email-resetPasswordButtonLabel": "Réinitialiser le mot de passe",
  "t-email-linkValidityMessage": "Le lien sera valide pendant 15 minutes.",
  "t-email-errorNotificationMessage":
    "Si vous pensez que cet e-mail a été envoyé par erreur, veuillez en informer immédiatement l'équipe d'inscription SolidSwap",
  "t-email-alternateLinkInstruction":
    "Si le lien d'activation ci-dessus ne fonctionne pas, veuillez copier le lien ci-dessous et le coller dans la barre d'adresse de votre navigateur, puis appuyez sur Entrée :",
  "t-email-confirmAccountMessage":
    "Veuillez confirmer votre compte SolidSwap en cliquant sur le bouton ci-dessous :",
  "t-email-confirmAccountButtonLabel": "Confirmer votre compte",
  "t-email-memberNameMessage": "Votre nom de membre SolidSwap est :",
  "t-email-accessSiteMessage": "Vous pouvez accéder au site en visitant",
  "t-email-activate2FAInstructions":
    "Pour commencer à trader ou effectuer votre premier dépôt, vous devrez activer l'authentification à deux facteurs. Vous pouvez le faire dans les paramètres de votre profil ou cliquer",
  "t-email-hereLabel": "ici",
  "t-email-beginnersGuideMessage":
    "Nous vous recommandons de consulter notre guide pour débutants",
  "t-email-communityWelcomeMessage":
    "Nous avons hâte de vous voir dans la communauté SolidSwap.",
  "t-email-exchangeStatusChangeNotification":
    "SolidSwap Demande d'échange : Notification de changement de statut",
  "t-email-withdrawalNotification": "Notification de retrait SolidSwap",
  "t-email-withdrawToLabel": "Retirer à :",
  "t-email-ipLabel": "IP :",
  "t-email-locationLabel": "Emplacement :",
  "t-email-unauthorizedActivityMessage":
    "Si vous ne reconnaissez pas cette activité, veuillez cliquer sur le bouton ci-dessous pour annuler la transaction et désactiver les retraits sur votre compte. Le lien n'est valide que pendant 10 minutes.",
  "t-email-cancelTransactionButtonLabel": "Annuler la transaction",
  "t-email-withdrawCode": "Votre code de retrait est :",
};
