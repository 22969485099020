export default {
  "c-footer-menu-trade": "comercio p2p",
  "c-footer-menu-news": "noticias",
  "c-footer-menu-blog": "blog",
  "c-footer-menu-faq": "FAQ",
  "c-footer-menu-beginnersGuide": "guía para principiantes",
  "c-footer-menu-about": "acerca de",
  "c-footer-menu-manageCookies": "gestionar cookies",
  "c-footer-menu-adminPanel": "panel de administración",

  "c-footer-mobilemenu-home": "Inicio",
  "c-footer-mobilemenu-trade": "Comercio",
  "c-footer-mobilemenu-orders": "Pedidos",
  "c-footer-mobilemenu-wallet": "Cartera",

  "c-footer-menu-serviceDescription":
    "Un servicio para intercambiar rápidamente criptomonedas y fiat en una plataforma P2P",
  "c-footer-menu-systemsNormal": "Todos los sistemas están operativos",
  "c-footer-menu-privacyPolicy": "Política de Privacidad",
  "c-footer-menu-instagramDescription":
    "¡Sigue nuestro Instagram para inspirarte con contenido visual y noticias de la industria cripto!",
  "c-footer-menu-linkedinDescription":
    "Únete a nuestra página de LinkedIn para construir conexiones profesionales y profundizar en los aspectos comerciales de las criptomonedas.",
  "c-footer-menu-xDescription":
    "¡Sigue nuestras actualizaciones en X.com para estar al tanto de tendencias y análisis del mercado cripto!",
  "c-footer-menu-telegramDescription":
    "¡Únete a nuestro Telegram para ser el primero en conocer noticias importantes y compartir tus ideas sobre criptomonedas!",
  "c-footer-menu-vkDescription":
    "Únete a nuestro VK para discutir las últimas noticias sobre criptomonedas",
};
