export default {
  "c-footer-menu-trade": "p2p trade",
  "c-footer-menu-news": "news",
  "c-footer-menu-blog": "blog",
  "c-footer-menu-faq": "FAQ",
  "c-footer-menu-beginnersGuide": "beginners guide",
  "c-footer-menu-about": "about",
  "c-footer-menu-manageCookies": "Manage cookies",
  "c-footer-menu-adminPanel": "Admin panel",

  "c-footer-mobilemenu-home": "Home",
  "c-footer-mobilemenu-trade": "Trade",
  "c-footer-mobilemenu-orders": "Orders",
  "c-footer-mobilemenu-wallet": "Wallet",

  "c-footer-menu-serviceDescription":
    "A service for fast cryptocurrency and fiat exchange on a P2P platform",
  "c-footer-menu-systemsNormal": "All systems are operational",
  "c-footer-menu-privacyPolicy": "Privacy Policy",
  "c-footer-menu-instagramDescription":
    "Follow our Instagram to get inspired by visual content and crypto industry news!",
  "c-footer-menu-linkedinDescription":
    "Join our LinkedIn page to build professional connections and dive into the business aspects of cryptocurrencies.",
  "c-footer-menu-xDescription":
    "Follow our updates on X.com to stay informed about trends and crypto market analytics!",
  "c-footer-menu-telegramDescription":
    "Join us on Telegram to be the first to know important news and share your thoughts on cryptocurrency!",
  "c-footer-menu-vkDescription":
    "Join us on VK to discuss the latest cryptocurrency news",
};
