export default {
  "c-footer-menu-trade": "P2P торговля",
  "c-footer-menu-news": "новости",
  "c-footer-menu-blog": "блог",
  "c-footer-menu-faq": "FAQ",
  "c-footer-menu-beginnersGuide": "руководство для начинающих",
  "c-footer-menu-about": "о нас",
  "c-footer-menu-manageCookies": "Управление куки",
  "c-footer-menu-adminPanel": "Панель администратора",
  "c-footer-mobilemenu-home": "Главная",
  "c-footer-mobilemenu-trade": "Торговля",
  "c-footer-mobilemenu-orders": "Заказы",
  "c-footer-mobilemenu-wallet": "Кошелек",

  "c-footer-menu-serviceDescription":
    "Сервис для быстрого обмена криптовалюты и фиата на P2P платформе",
  "c-footer-menu-systemsNormal": "Все системы в норме",
  "c-footer-menu-privacyPolicy": "Политика конфиденциальности",
  "c-footer-menu-instagramDescription":
    "Подпишитесь на наш Instagram, чтобы вдохновляться визуальным контентом и новостями криптоиндустрии!",
  "c-footer-menu-linkedinDescription":
    "Присоединяйтесь к нашей странице на LinkedIn, чтобы строить профессиональные связи и углубляться в бизнес-аспекты криптовалют.",
  "c-footer-menu-xDescription":
    "Следите за нашими обновлениями на X.com, чтобы быть в курсе трендов и аналитики крипторынка!",
  "c-footer-menu-telegramDescription":
    "Присоединяйся к нам в Telegram, чтобы первыми узнавать важные новости и делиться своими мыслями о криптовалюте!",
  "c-footer-menu-vkDescription":
    "Присоединяйся к нам ВКонтакте для обсуждения последних новостей криптовалюты",
};
