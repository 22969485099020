export default {
  "p-exchange-exchange": "Échange",
  "p-exchange-for": "pour",
  "p-exchange-fixed-rate":
    "Le taux de change sera fixé après la création de la demande",
  "p-exchange-current-rate": "Le taux de change actuel pour cette paire est :",
  "p-exchange-send": "Envoyer",
  "p-exchange-amount-to-send": "Montant à envoyer",
  "p-exchange-crypto": "Crypto",
  "p-exchange-fiat": "Fiat",
  "p-exchange-card-number-verification":
    "Votre numéro de carte : (nécessaire pour la vérification)",
  "p-exchange-card-tooltip":
    "Avant d'accepter votre argent, nous devons vérifier votre identité et vérifier si la carte est sur des listes noires",
  "p-exchange-learn-more": "(en savoir plus ?)",
  "p-exchange-wallet-verification":
    "Votre portefeuille {currency} : (nécessaire pour la vérification ALM)",
  "p-exchange-wallet-tooltip":
    "La vérification ALM doit vérifier les cryptomonnaies, SolidSwap garantit que toutes les transactions de cryptomonnaies sont 'propres'",
  "p-exchange-enter-wallet": "Saisissez le portefeuille ici",
  "p-exchange-receive": "Recevoir",
  "p-exchange-on": "sur {name}",
  "p-exchange-amount-to-receive": "Montant à recevoir",
  "p-exchange-card-number": "Numéro de carte",
  "p-exchange-wallet-receive": "Portefeuille pour recevoir",
  "p-exchange-email-required": "E-mail (obligatoire)",
  "p-exchange-terms-message": "J'ai lu et j'accepte les",
  "p-exchange-terms": "Conditions d'utilisation",
  "p-exchange-and": "et",
  "p-exchange-privacy": "Politique de confidentialité",
  "p-exchange-alm-message": "J'ai lu et j'accepte les",
  "p-exchange-captcha": "Captcha",
  "p-exchange-not-allowed": "Non autorisé",
  "p-exchange-not-allowed-message":
    "Les administrateurs et modérateurs de SolidSwap ne sont pas autorisés à créer une demande d'échange",
  "p-exchange-proceed": "Procéder à l'échange",
  "p-exchange-menu-create-request": "Créer une demande d'échange",
  "p-exchange-menu-requests": "Demandes",
  "p-exchange-accept-terms": "Veuillez accepter les conditions d'utilisation",
  "p-exchange-accept-alm": "Veuillez lire et accepter l'AML/CTF et KYC",
  "p-exchange-enter-captcha": "Veuillez entrer le captcha",
  "p-exchange-send-amount-min":
    "Le montant à envoyer ne doit pas être inférieur à {amount} {currency}",
  "p-exchange-recieve-amount-min":
    "Le montant à recevoir ne doit pas être inférieur à {amount} {currency}",
  "p-exchange-send-amount-max":
    "Le montant à envoyer ne doit pas dépasser {amount} {currency}",
  "p-exchange-recieve-amount-max":
    "Le montant à recevoir ne doit pas dépasser {amount} {currency}",
  "p-exchange-send-amount-required": "Le montant à envoyer est requis",
  "p-exchange-recieve-amount-required": "Le montant à recevoir est requis",
  "p-exchange-public-address-required":
    "Votre adresse de portefeuille est requise",
  "p-exchange-card-number-required": "Votre numéro de carte est requis",
  "p-exchange-rates-updated-now": "Les taux seront mis à jour maintenant !",
  "p-exchange-rates-updated-in":
    "Les taux seront mis à jour dans {minutes}:{seconds}",
  "p-exchange-procees-to-search": "Procéder à la création d'un échange",
  "p-exchange-offline-title": "Notre bourse est hors ligne !",
  "p-exchange-offline-par":
    "Notre bourse est actuellement hors ligne pour maintenance pendant les heures nocturnes. Nous nous excusons pour le désagrément et apprécions votre compréhension. Veuillez revenir plus tard.",
};
