export default {
  "t-email-logoAltText": "SolidSwap Logo",
  "t-email-greeting": "Hi,",
  "t-email-failedLoginAttemptMessage":
    "You have a failed login attempts on your SolidSwap account! Your login has been blocked for 1 hour.",
  "t-email-lastFailedLoginAttemptMessage":
    "The last failed login attempt was from this device and location:",
  "t-email-serverLabel": "Server:",
  "t-email-ipAddressLabel": "IP address:",
  "t-email-countryDistrictLabel": "Country/District:",
  "t-email-timestampLabel": "Timestamp:",
  "t-email-compromisedAccountMessage":
    "If you believe your account has been compromised, please contact us immediately via email",
  "t-email-registeredEmailMessage":
    "using your registered email address, or you can click the button below to block withdrawals.",
  "t-email-blockWithdrawalsButtonText": "Block Withdrawals",
  "t-email-blockWithdrawalsButtonLabel": "Block Withdrawals",
  "t-email-thankYouMessage": "Thank you,",
  "t-email-teamMessage": "The SolidSwap Team",
  "t-email-loginFromNewDeviceMessage":
    "You have logged into your SolidSwap account from a new device or location:",
  "t-email-newExchangeRequestSubject": "SolidSwap: New exchange request",
  "t-email-fromCurrencyLabel": "From currency:",
  "t-email-fromAmountLabel": "Amount:",
  "t-email-toCurrencyLabel": "To currency:",
  "t-email-toAmountLabel": "Amount:",
  "t-email-statusLabel": "Status:",
  "t-email-dateTimeLabel": "Date/Time:",
  "t-email-requestIdLabel": "Request ID:",
  "t-email-goToRequestPageButtonLabel": "Go to exchange request page",
  "t-email-noReplyMessage": "Please do not reply to this email.",
  "t-email-supportRequestNotification":
    "SolidSwap New Support Request notification",
  "t-email-userNameLabel": "User name:",
  "t-email-userEmailLabel": "User email:",
  "t-email-helpMessageLabel": "Help message:",
  "t-email-checkSupportChatsMessage": "Please check the support chats tab:",
  "t-email-orderCreationNotification": "SolidSwap Order creation notification",
  "t-email-currencyLabel": "Currency:",
  "t-email-quantityLabel": "Quantity:",
  "t-email-goToOrderPageButtonLabel": "Go to order page",
  "t-email-passwordResetRequestMessage": "You have requested a password reset!",
  "t-email-passwordResetInstructionMessage":
    "Please proceed to reset your SolidSwap account password by clicking the button below:",
  "t-email-resetPasswordButtonLabel": "Reset Password",
  "t-email-linkValidityMessage": "The link will be valid for 15 minutes.",
  "t-email-errorNotificationMessage":
    "If you believe that this email was sent in error, please notify the SolidSwap registration team immediately at",
  "t-email-alternateLinkInstruction":
    "If the activation link above does not work, please copy the link below and paste it into the 'Address' line of your web browser and click enter:",
  "t-email-confirmAccountMessage":
    "Please confirm your SolidSwap account by clicking the button below:",
  "t-email-confirmAccountButtonLabel": "Confirm Your Account",
  "t-email-memberNameMessage": "Your SolidSwap Member name is:",
  "t-email-accessSiteMessage": "You can access the site by going to",
  "t-email-activate2FAInstructions":
    "To start trading or to make your first deposit you will need to activate Two-factor authentication. You can do that under Settings of your profile or click",
  "t-email-hereLabel": "here",
  "t-email-beginnersGuideMessage":
    "To get started we recommend you go over our Beginners Guide",
  "t-email-communityWelcomeMessage":
    "We look forward to seeing you in the SolidSwap community.",
  "t-email-exchangeStatusChangeNotification":
    "SolidSwap Exchange Request: Status Change Notification",
  "t-email-withdrawalNotification": "SolidSwap Withdrawal Notification",
  "t-email-withdrawToLabel": "Withdraw to:",
  "t-email-ipLabel": "IP:",
  "t-email-locationLabel": "Location:",
  "t-email-unauthorizedActivityMessage":
    "If you do not recognize this activity, please click the button below to cancel the transaction and disable withdrawal on your account. The link is valid for 10 minutes only.",
  "t-email-cancelTransactionButtonLabel": "Cancel Transaction",
  "t-email-withdrawCode": "Your withdrawal code is:",
};
