export default {
  "p-index-head-title":
    "Solid Swap | Échange de cryptomonnaies sécurisé et efficace",
  "p-index-head-description":
    "Découvrez un trading de cryptomonnaies rapide, sécurisé et efficace avec Solid Swap. Inscrivez-vous maintenant pour commencer à échanger des actifs numériques en toute simplicité",
  "p-index-head-keywords":
    "échange de cryptomonnaies, trading sécurisé, actifs numériques, trading de crypto, Solid Swap, solidswap",
  "p-index-prices-finder": "Chercheur de prix :",
  "p-index-welcome-no-fees":
    "Aucun frais pour l'acheteur, frais ultra bas de 0,2% pour le vendeur",
  "p-index-welcome-stable": "Stable, légalisé et sans ALM",
  "p-index-welcome-no-id": "Aucune vérification d'identité requise",
  "p-index-welcome-message-2":
    "Tradez à votre manière, à tout moment, n'importe où",
  "p-index-get-started": "Commencer",
  "p-index-learn-more": "EN SAVOIR PLUS",
  "p-index-world-opening-title": "Nous vous ouvrons le monde",
  "p-index-world-opening-message-1":
    "La cryptomonnaie vous rendra libre, c'est la liberté des chaînes des banques, des dictats du gouvernement",
  "p-index-world-opening-message-2":
    "C'est la liberté de mouvement à travers le monde et la possibilité de sauvegarder votre argent dans n'importe quel pays",
  "p-index-mobile-app-title": "Téléchargez l'application mobile gratuitement",
  "p-index-mobile-app-message":
    "L'application mobile est disponible en téléchargement sur l'App Store et Google Play",
  "p-index-coming-soon": "Disponible cet été",
  "p-index-subscribe-title": "Abonnez-vous à Solid Swap",
  "p-index-welcome-title": "Avantages de notre P2P trade",
  "p-index-our-blog": "Notre blog :",
};
