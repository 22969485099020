export default {
  "t-email-logoAltText": "Логотип SolidSwap",
  "t-email-greeting": "Привет,",
  "t-email-failedLoginAttemptMessage":
    "У вас была неудачная попытка входа в вашу учетную запись SolidSwap! Ваш доступ заблокирован на 1 час.",
  "t-email-lastFailedLoginAttemptMessage":
    "Последняя неудачная попытка входа была с этого устройства и в этом месте:",
  "t-email-serverLabel": "Сервер:",
  "t-email-ipAddressLabel": "IP-адрес:",
  "t-email-countryDistrictLabel": "Страна/Регион:",
  "t-email-timestampLabel": "Время:",
  "t-email-compromisedAccountMessage":
    "Если вы считаете, что ваша учетная запись была взломана, немедленно свяжитесь с нами по электронной почте",
  "t-email-registeredEmailMessage":
    "с использованием вашего зарегистрированного адреса электронной почты, либо вы можете нажать на кнопку ниже, чтобы заблокировать вывод средств.",
  "t-email-blockWithdrawalsButtonText": "Заблокировать вывод средств",
  "t-email-blockWithdrawalsButtonLabel": "Заблокировать вывод средств",
  "t-email-thankYouMessage": "Спасибо,",
  "t-email-teamMessage": "Команда SolidSwap",
  "t-email-loginFromNewDeviceMessage":
    "Вы вошли в свою учетную запись SolidSwap с нового устройства или из нового места:",
  "t-email-newExchangeRequestSubject": "SolidSwap: Новый запрос обмена",
  "t-email-fromCurrencyLabel": "Из валюты:",
  "t-email-fromAmountLabel": "Сумма:",
  "t-email-toCurrencyLabel": "В валюту:",
  "t-email-toAmountLabel": "Сумма:",
  "t-email-statusLabel": "Статус:",
  "t-email-dateTimeLabel": "Дата/Время:",
  "t-email-requestIdLabel": "ID запроса:",
  "t-email-goToRequestPageButtonLabel": "Перейти на страницу запроса обмена",
  "t-email-noReplyMessage": "Пожалуйста, не отвечайте на это письмо.",
  "t-email-supportRequestNotification":
    "Уведомление о новом запросе поддержки SolidSwap",
  "t-email-userNameLabel": "Имя пользователя:",
  "t-email-userEmailLabel": "Электронная почта пользователя:",
  "t-email-helpMessageLabel": "Сообщение помощи:",
  "t-email-checkSupportChatsMessage":
    "Пожалуйста, проверьте вкладку чатов поддержки:",
  "t-email-orderCreationNotification":
    "Уведомление о создании заказа SolidSwap",
  "t-email-currencyLabel": "Валюта:",
  "t-email-quantityLabel": "Количество:",
  "t-email-goToOrderPageButtonLabel": "Перейти на страницу заказа",
  "t-email-passwordResetRequestMessage": "Вы запросили сброс пароля!",
  "t-email-passwordResetInstructionMessage":
    "Пожалуйста, перейдите к сбросу пароля для вашей учетной записи SolidSwap, нажав на кнопку ниже:",
  "t-email-resetPasswordButtonLabel": "Сбросить пароль",
  "t-email-linkValidityMessage":
    "Ссылка будет действительна в течение 15 минут.",
  "t-email-errorNotificationMessage":
    "Если вы считаете, что это письмо было отправлено по ошибке, немедленно уведомьте команду регистрации SolidSwap",
  "t-email-alternateLinkInstruction":
    "Если вышеприведенная ссылка не работает, скопируйте ссылку ниже и вставьте её в адресную строку вашего браузера, затем нажмите Enter:",
  "t-email-confirmAccountMessage":
    "Пожалуйста, подтвердите свою учетную запись SolidSwap, нажав на кнопку ниже:",
  "t-email-confirmAccountButtonLabel": "Подтвердить учетную запись",
  "t-email-memberNameMessage": "Ваше имя пользователя SolidSwap:",
  "t-email-accessSiteMessage": "Вы можете получить доступ к сайту, перейдя по",
  "t-email-activate2FAInstructions":
    "Для начала торговли или выполнения первого депозита вам необходимо включить двухфакторную аутентификацию. Вы можете сделать это в настройках своего профиля или нажмите",
  "t-email-hereLabel": "здесь",
  "t-email-beginnersGuideMessage":
    "Мы рекомендуем вам ознакомиться с нашим Руководством для начинающих",
  "t-email-communityWelcomeMessage":
    "Мы рады видеть вас в сообществе SolidSwap.",
  "t-email-exchangeStatusChangeNotification":
    "Запрос обмена SolidSwap: Уведомление о смене статуса",
  "t-email-withdrawalNotification": "Уведомление о выводе средств SolidSwap",
  "t-email-withdrawToLabel": "Вывести на:",
  "t-email-ipLabel": "IP:",
  "t-email-locationLabel": "Местоположение:",
  "t-email-unauthorizedActivityMessage":
    "Если вы не распознаёте эту активность, пожалуйста, нажмите на кнопку ниже, чтобы отменить транзакцию и заблокировать вывод средств на вашем аккаунте. Ссылка действительна только в течение 10 минут.",
  "t-email-cancelTransactionButtonLabel": "Отменить транзакцию",
  "t-email-withdrawCode": "Ваш код для вывода средств:",
};
