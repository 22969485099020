export default {
  "t-email-logoAltText": "SolidSwap 标志",
  "t-email-greeting": "你好,",
  "t-email-failedLoginAttemptMessage":
    "您在 SolidSwap 账户上有一次失败的登录尝试！您的登录已被封锁 1 小时。",
  "t-email-lastFailedLoginAttemptMessage":
    "最近一次失败的登录尝试来自此设备和位置：",
  "t-email-serverLabel": "服务器：",
  "t-email-ipAddressLabel": "IP 地址：",
  "t-email-countryDistrictLabel": "国家/地区：",
  "t-email-timestampLabel": "时间戳：",
  "t-email-compromisedAccountMessage":
    "如果您认为您的账户已被盗，请立即通过电子邮件联系我们",
  "t-email-registeredEmailMessage":
    "使用您的注册电子邮件地址，或您可以点击下方按钮来封锁取款。",
  "t-email-blockWithdrawalsButtonText": "封锁取款",
  "t-email-blockWithdrawalsButtonLabel": "封锁取款",
  "t-email-thankYouMessage": "谢谢，",
  "t-email-teamMessage": "SolidSwap 团队",
  "t-email-loginFromNewDeviceMessage":
    "您从新设备或位置登录了您的 SolidSwap 账户：",
  "t-email-newExchangeRequestSubject": "SolidSwap：新的兑换请求",
  "t-email-fromCurrencyLabel": "从货币：",
  "t-email-fromAmountLabel": "金额：",
  "t-email-toCurrencyLabel": "到货币：",
  "t-email-toAmountLabel": "金额：",
  "t-email-statusLabel": "状态：",
  "t-email-dateTimeLabel": "日期/时间：",
  "t-email-requestIdLabel": "请求 ID：",
  "t-email-goToRequestPageButtonLabel": "前往兑换请求页面",
  "t-email-noReplyMessage": "请不要回复此邮件。",
  "t-email-supportRequestNotification": "SolidSwap 新支持请求通知",
  "t-email-userNameLabel": "用户名：",
  "t-email-userEmailLabel": "用户邮箱：",
  "t-email-helpMessageLabel": "帮助信息：",
  "t-email-checkSupportChatsMessage": "请查看支持聊天标签：",
  "t-email-orderCreationNotification": "SolidSwap 订单创建通知",
  "t-email-currencyLabel": "货币：",
  "t-email-quantityLabel": "数量：",
  "t-email-goToOrderPageButtonLabel": "前往订单页面",
  "t-email-passwordResetRequestMessage": "您请求了密码重置！",
  "t-email-passwordResetInstructionMessage":
    "请点击下方按钮重置您的 SolidSwap 账户密码：",
  "t-email-resetPasswordButtonLabel": "重置密码",
  "t-email-linkValidityMessage": "该链接有效期为 15 分钟。",
  "t-email-errorNotificationMessage":
    "如果您认为此邮件有误，请立即通知 SolidSwap 注册团队",
  "t-email-alternateLinkInstruction":
    "如果上方的激活链接无效，请复制下方的链接并粘贴到您的浏览器地址栏中，然后按回车：",
  "t-email-confirmAccountMessage": "请点击下方按钮确认您的 SolidSwap 账户：",
  "t-email-confirmAccountButtonLabel": "确认您的账户",
  "t-email-memberNameMessage": "您的 SolidSwap 会员名为：",
  "t-email-accessSiteMessage": "您可以通过以下链接访问该网站",
  "t-email-activate2FAInstructions":
    "开始交易或进行首次存款，您需要启用双因素认证。您可以在您的个人资料设置中启用，或点击",
  "t-email-hereLabel": "这里",
  "t-email-beginnersGuideMessage": "我们建议您先浏览我们的初学者指南",
  "t-email-communityWelcomeMessage": "我们期待在 SolidSwap 社区中见到您。",
  "t-email-exchangeStatusChangeNotification":
    "SolidSwap 兑换请求：状态更改通知",
  "t-email-withdrawalNotification": "SolidSwap 提款通知",
  "t-email-withdrawToLabel": "提款至：",
  "t-email-ipLabel": "IP：",
  "t-email-locationLabel": "位置：",
  "t-email-unauthorizedActivityMessage":
    "如果您不认识此操作，请点击下方按钮取消交易并在您的账户上禁用提款。该链接仅在 10 分钟内有效。",
  "t-email-cancelTransactionButtonLabel": "取消交易",
  "t-email-withdrawCode": "您的提款代码是：",
};
